(function($){

    //Handle INITIAL load
    var container = $('#links_blocks .services_blocks');
    var window_width = $(window).width();
    if (window_width <= 767) {
        init_slick(container);
    }

    function init_slick(container) {
        container.not('.slick-initialized').slick({
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
            speed: 1000,
            centerMode: true,
            centerPadding: '55px',
            responsive: [
                {
                    breakpoint: 9999,
                    settings: "unslick"
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        }).on('resize', function () {
            container.slick('resize');
        });
    }

    // Handle RESPONSIVE load
    $(window).resize(function(e){
        var container = $('#links_blocks .services_blocks');
        var window_width = $(window).width();
        if (window_width <= 767) {
            init_slick(container);
        }
    });

})(jQuery);